import React, { Component } from 'react'
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next'; 

import { getApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions"

import InfosModalMAJ from '../Modal/InfosModalMAJ'
import SectionHeroHome from './SectionHeroHome'
import SectionPushBrand from './SectionPushBrand'
import OverlayTutorialHome from './OverlayTutorialHome'
import SatisfactionFormModal from '../Modal/SatisfactionFormModal';

class PageHome extends Component {
	constructor(props) {
		super(props)
		this.state = {
			apiDatas:[],
			AffichageTemporaire:true,
			AttenteAPiTemporaire:false,
		}

	}
	async componentDidMount() {
		window.scrollTo(0,0)

		const apiDatas = await getApiDatas(`/apiaox/Home/GetDataPageHome`)
		this.setState({ apiDatas:apiDatas, AttenteAPiTemporaire:true})

		setTimeout(() => {
			this.setState({ openTutorialHome: true });
			window.scrollTo(
				{ top: 0, left: 0, behavior: 'smooth'}
			)
		}, 4000);

		const IsSatisfaction_Form = apiDatas?.User?.SatisfactionForm?.Url && !localStorage.getItem(apiDatas?.User?.SatisfactionForm?.Code)
		//popin formulaire satisfaction
		if(IsSatisfaction_Form){
			setTimeout(() => {
				this.SatisfactionFormModal && this.SatisfactionFormModal.openModal();
			}, 5);
		}

		//popin tutorial
		if(!this.state.AffichageTemporaire && !IsSatisfaction_Form && !localStorage.getItem('dontShowAgainModalHomeTutorial')){
			setTimeout(() => {
				this.OverlayTutorialHome && this.OverlayTutorialHome.openModal();
			}, 5000);
		}

		// ouverture popin Info
		if(this.props.fields.data != null
		&& this.props.fields.data.CurrentUser != null
		&& this.props.fields.data.CurrentUser.needcontactinformation != null
		&& this.props.fields.data.CurrentUser.needcontactinformation === true ) {
			this.InfosModalMAJ.openModal()
		}
	}
	
	render() {
		const scFields = this.props.sitecoreContext.route?.fields;
		const scDatas = this.props.fields.data;
		const apiDatas = this.state.apiDatas;

		return (this.state.AttenteAPiTemporaire && <>
			{apiDatas?.User?.SatisfactionForm && 
				<SatisfactionFormModal onRef={ref => (this.SatisfactionFormModal = ref)} modalUrlInfosPeros={scDatas?.PageInfoPerso.url} SatisfactionForm={apiDatas?.User?.SatisfactionForm} />
			}
			<OverlayTutorialHome onRef={ref => (this.OverlayTutorialHome = ref)} apiDatas={apiDatas}/>

			<SectionHeroHome scFields={scFields} scDatas={scDatas} apiDatas={apiDatas}/>
			{/* masquage : 11949
				<SectionPushBrand scFields={scFields}/> 
			*/}

			<InfosModalMAJ onRef={ref => (this.InfosModalMAJ = ref)} modalUrlInfosPeros={scDatas?.PageInfoPerso.url} />
		</>)
	}
}

export default withTranslation()(withSitecoreContext()(PageHome));