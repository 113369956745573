import React, { Component } from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react';

import { throttle, isMobile, isNotAtTop } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"

import ObjBrandHeroHome from './ObjBrandHeroHome'
import ObjBrandHighlightedHeroHome from './ObjBrandHighlightedHeroHome'
import ObjNewsCarouselHeroHome from './ObjNewsCarouselHeroHome'
import ObjEntryHeroHome from './ObjEntryHeroHome'

class SectionHeroHome extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isMobile: isMobile(),
			modeMini: isNotAtTop(),
		}
		this.handleWindowScrollMainHeader = this.handleWindowScrollMainHeader.bind(this)
		this.throttleWindowScrollMainHeader = throttle(this.handleWindowScrollMainHeader, 200)
	}
	componentDidMount() {
		window.addEventListener('resize', this.throttleWindowResizeMainHeader)
		window.addEventListener('scroll', this.throttleWindowScrollMainHeader)
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.throttleWindowResizeMainHeader)
		window.removeEventListener('scroll', this.throttleWindowScrollMainHeader)
	}
	handleWindowScrollMainHeader() {
		this.setState({
			modeMini: isNotAtTop()
		})
	}

	render() {
		const {scFields, scDatas, apiDatas} = this.props;

		return (<>
			<section className={`obj_section section_hero has_background${this.state.modeMini ? ' is_blur' : ''}`}> 
				<div className="ctn_section">
					
					<div className="obj_links_hero_home">
						<div className="ctn_left_hero_home">
							<div className="ctn_title">
								<h1 className="t_title">{scFields && scFields["Page SubTitle"]?.value}</h1>
							</div>
                            <ObjNewsCarouselHeroHome scFields={scFields} scDatas={scDatas}/>
							<ObjBrandHighlightedHeroHome scFields={scFields} scDatas={scDatas}/>
						</div>
						<div className="ctn_right_hero_home">
							<ObjBrandHeroHome scDatas={scDatas}/>
						</div>
					</div>
					
					<ObjEntryHeroHome scDatas={scDatas} apiDatas={apiDatas}/>

				</div>

				<picture className="ctn_background fade-in">
					<source srcSet={scFields && scFields["Page image mobile"]?.value.src} media="(max-width:767px)"/>
					<Image field={scFields && scFields["Page Image"]?.value}/>
				</picture>
			</section>
		</>)
	}
}

export default SectionHeroHome